@import '../../../../scss/theme-bootstrap';

.gnav-links {
  @include breakpoint($bp--largest-up) {
    height: auto;
  }
  &__trigger {
    &:checked {
      ~ .gnav-links__content {
        display: block;
        .gnav-link {
          line-height: 45px;
          align-items: center;
          @include breakpoint($bp--largest-up) {
            line-height: 1.3;
          }
        }
      }
      ~ .gnav-links__header {
        .gnav-links__mobile-icon--minus {
          display: inline-block;
        }
        .gnav-links__mobile-icon--plus {
          display: none;
        }
        .gnav-links__header-label {
          box-shadow: none;
        }
      }
    }
  }
  &__header {
    position: relative;
    height: $gnav-mobile-link-height;
    display: flex;
    align-items: center;
    @include breakpoint($bp--largest-up) {
      height: $gnav-link-height;
    }
  }
  &__header-link {
    border-bottom: none;
  }
  &__header-label {
    display: block;
    width: 100%;
    line-height: $gnav-mobile-link-height;
    font-family: $ano-black_regular-font;
    margin: 0 $gnav-mobile-hpad--outer;
    box-shadow: 0 1px 0 0 $color--gray--white;
    @include breakpoint($bp--largest-up) {
      display: inline-block;
      margin: 0;
      line-height: inherit;
      width: auto;
      box-shadow: none;
    }
    .gnav-links:last-child & {
      box-shadow: none;
    }
  }
  &__mobile-section-toggle {
    opacity: 1;
    position: absolute;
    top: 0;
    #{$rdirection}: 20px;
    @include breakpoint($bp--largest-up) {
      display: none;
    }
  }
  &__content {
    display: none;
    @include breakpoint($bp--largest-up) {
      display: block;
    }
    .gnav-links__header ~ & {
      @include swap_direction(padding, 12px 0 12px #{2 * $gnav-mobile-hpad--outer});
      margin-top: -17px;
      background-color: $color--gray--lightest;
      @include breakpoint($bp--largest-up) {
        background-color: transparent;
        padding: 0;
        margin-top: 0;
        height: calc(100% - #{$gnav-link-height});
      }
      .only-gnav-link & {
        margin-top: 0;
      }
    }
  }
  &__content-links {
    .gnav-links--columns-break & {
      @include breakpoint($bp--largest-up) {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin-bottom: $gnav-link-height;
      }
      @for $i from 1 through 10 {
        :not(.gnav-links__link--break).gnav-links__link:nth-child(#{$i}) {
          @include breakpoint($bp--largest-up) {
            order: $i;
          }
        }
        &--break-afer-#{$i} {
          @include breakpoint($bp--largest-up) {
            height: $i * $gnav-link-height;
          }
          .gnav-links__link--break {
            display: none;
            @include breakpoint($bp--largest-up) {
              display: flex;
              flex-basis: 100%;
              width: 0;
              overflow: hidden;
              order: $i;
            }
          }
        }
      }
    }
  }
  &__link {
    @include breakpoint($bp--largest-up) {
      height: $gnav-link-height;
    }
    .gnav-link {
      color: $color--black;
      text-transform: none;
      @include breakpoint($bp--largest-up) {
        line-height: 1.3;
      }
      &,
      &:visited {
        color: $color--black;
        border-bottom-color: transparent;
      }
      &:active,
      &:focus,
      &:hover {
        color: $color--black;
        border-bottom-color: transparent;
        @include breakpoint($bp--largest-up) {
          border-bottom-color: $color--black;
        }
      }
    }
    &--viewall {
      @include breakpoint($bp--largest-up) {
        line-height: 1;
        height: auto;
        position: absolute;
        bottom: 0;
      }
      .gnav-link,
      .gnav-link:visited {
        color: $color--black;
        @include breakpoint($bp--largest-up) {
          border-bottom-color: $color--black;
        }
      }
      .gnav-links--columns-break & {
        @include breakpoint($bp--largest-up) {
          width: auto;
        }
      }
    }
    .gnav-links--columns-break & {
      @include breakpoint($bp--largest-up) {
        width: 50%;
      }
      &.gnav-links__link--viewall {
        @include breakpoint($bp--largest-up) {
          width: auto;
        }
      }
    }
  }
  &__viewall-link {
    @include breakpoint($bp--largest-up) {
      line-height: 1;
    }
  }
  &__mobile-icon {
    display: inline-block;
    height: $gnav-icon--inline;
    width: $gnav-icon--inline;
    &--plus {
      height: 8px;
      width: 8px;
    }
    &--minus {
      display: none;
      height: 8px;
      width: 8px;
    }
  }
}
